<template>
  <li v-if="information" class="request__details__information">
    <div class="d-block ml-1">
      <div class="request__details__information--title">
        {{ information.title }}
      </div>
      <div class="request__details__information--sub-title">
        {{ information.subTitle }}
      </div>
    </div>
  </li>
  <li v-else class="request__details__information">
    <div class="d-block ml-1">
      <div :style="isChanged===true?'color:red':''" class="request__details__information--title">
        {{ title }}
      </div>
      <component
        :is="tag"
        :style="isChanged===true?'color:red':''"
        :class="['request__details__information--sub-title', {'html-noscroll':tag==='html'},{ 'ml-n1': tag === 'pre' }]"
        v-html="subTitle!=null ? subTitle : '-'"
      >
      </component>
      <slot v-if="isChanged===true"></slot>
    </div>
  </li>
</template>
<script>
export default {
  props: {
    information: {
      type: Object,
      default: null,
    },
    isChanged: {
      default: false,
    },
    title: {},
    subTitle: {},
    tag: {
      type: String,
      default: "div",
    },
  },
};
</script>
<style scoped>
.request__details__information {
  display: flex;
  margin: 0 70px 0 0;
}
.request__details__information::before {
  height: 24px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 2px 0 0;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 11px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.request__details__information--sub-title {
  font-size: 15px;
  font-family: "Montserrat";
  color: var(--color-black);
  position: relative;
  top: -0.3rem;
}
.wa__basic__info__information--information
  li
  .d-block
  .request__details__information--title {
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
  font-size: 13px;
}
.wa__basic__info__information--information li .request__details__information--sub-title,
.wa__japanese__info--info li .request__details__information--sub-title,
.wa__general__info--info li .request__details__information--sub-title,
.wa__physical__info--info li .request__details__information--sub-title,
.wa__bank__info--info li .request__details__information--sub-title {
  top: -0.3rem;
  color: var(--color-black);
  font-family: "Montserrat-regular";
  font-size: 15px;
  margin-bottom: 16px;
  font-weight: 600;
}
</style>
